import React from "react";


// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";



// Material Dashboard 2 React components (Material UI components used)
function ComplexStatisticsCard({ color, title, count, colorEscrita, icon, colorIcon, tamanhoIcon, amount , sublabel}) {
    return (
        <Card component={Paper} elevation={10}  >
            <Box padding={2}>
                <Box bgcolor={color} padding={2}   borderRadius="10px" position='absolute' top='73px' width='54px' height='34px'  display="flex"   justifyContent="center"   alignItems="center">
                {React.createElement(icon, { style: { fontSize: tamanhoIcon, color: colorIcon } })}
                </Box>
                <Box textAlign="right" lineHeight={1.25}>
                    <Typography variant="h3" fontWeight="light" color="textSecondary" >
                        {title}
                    </Typography>
                    <Typography variant="h5"  color={colorEscrita}>{count}</Typography>
                </Box>
            </Box>
            <Divider />
            <Box pb={2} px={2}>
                <Typography component="p" variant="button" color="textSecondary" display="flex">
                    <Typography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color={colorEscrita}
                    >
                        {amount}
                    </Typography>
                    &nbsp;{sublabel}
                </Typography>
            </Box>
        </Card>
    );
}



export default ComplexStatisticsCard;
