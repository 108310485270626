import { Box, Grid } from "@mui/material";

import {North} from "@mui/icons-material";
import { ComplexStatisticsCard } from "../../components";

export default function TesteDashboard() {
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>                    
                        <ComplexStatisticsCard
                             color="#312f2f"
                             colorIcon='#fff'
                             tamanhoIcon='38px'
                            icon={North}
                            title="Entradas"
                            count={'R$  150.000,05'}
                            amount= "+55%"
                            colorEscrita="#08fa15"
                            sublabel='teste'
                        />                   
                </Grid>
                <Grid item xs={12} md={6} lg={3}>                    
                        <ComplexStatisticsCard
                             color="#312f2f"
                             colorIcon='#fff'
                             tamanhoIcon='38px'
                            icon={North}
                            title="Entradas"
                            count={'R$  150.000,05'}
                            amount= "+55%"
                            colorEscrita="#08fa15"
                            sublabel='teste'
                        />                   
                </Grid>
                <Grid item xs={12} md={6} lg={3}>                    
                        <ComplexStatisticsCard
                             color="#312f2f"
                             colorIcon='#fff'
                             tamanhoIcon='38px'
                            icon={North}
                            title="Entradas"
                            count={'R$  150.000,05'}
                            amount= "+55%"
                            colorEscrita="#08fa15"
                            sublabel='teste'
                        />                   
                </Grid>
                <Grid item xs={12} md={6} lg={3}>                    
                        <ComplexStatisticsCard
                             color="#312f2f"
                             colorIcon='#fff'
                             tamanhoIcon='38px'
                            icon={North}
                            title="Entradas"
                            count={'R$  150.000,05'}
                            amount= "+55%"
                            colorEscrita="#08fa15"
                            sublabel='teste'
                        />                   
                </Grid>
            </Grid>
        </Box>
    );
}