import { Box, Paper, Typography, TextField } from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../components/Button/CustomButon';
import UsersService from '../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';

export default function Mensal() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [errorDetails, setErrorDetails] = useState([]); // To store error messages

    const mensagem = `Prezado Cliente!!
        Passando para reforçar, sobre os arquivos de envios ao E-Social.
        
        Toda e qualquer movimentação no quadro de funcionários da empresa deverá nos ser enviado como:
        
        🟠(Contratação, Demissão, Mudança de Função);
        
        Assim como também os Exames:
        
        🟠(Admissional, Demissional e Mudança de Riscos);
        
        ⚠Temos prazo, a ser enviado até o dia 13 do mês subsequente, evitando assim MULTAS de possíveis Orgãos Fiscalizadores.
        
        Qualquer dúvida entre em contato com a: ⬇
        👷🏻‍♀Maira TST - ASSO Consultoria
        📥 maira.assoconsultoria@gmail.com
        📞 (62) 9 9690-792`;

    const handleSendMessage = async () => {
        setIsLoading(true);
        setErrorDetails([]);

        let data = {
            mensagem: mensagem
        };

        try {
            const res = await UsersService.create(data, 'mensagem/mensal');
            dispatch(changeloading({ open: false, msg: 'disparando mensagens ...' }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
        } catch (error) {
            dispatch(changeloading({ open: false }));
            const errors = error.response?.data?.falhas || [];
            setErrorDetails(errors);
            dispatch(changeNotify({ open: true, class: 'error', msg: 'Erro ao enviar mensagens.' }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box>
            <Box component={Paper} padding={2} marginBottom={2}>
                <Typography variant='h4' sx={{ fontSize: '25px !important' }}>
                    Aqui você irá disparar mensagem para todos clientes ativos do sistema!!
                </Typography>
            </Box>

            <Box component={Paper} padding={2}>
                <Typography variant='h4' sx={{ fontSize: '18px !important' }}>
                    Clique no botão e dispare a mensagem padrão:
                </Typography>
                <Box marginTop={2}>
                    <Typography> {mensagem} </Typography>
                </Box>
                <Box marginTop={2}>
                    <CustomButton
                        onClick={handleSendMessage}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Enviando...' : 'Enviar'}
                    </CustomButton>
                </Box>
                {errorDetails.length > 0 && (
                    <Box marginTop={4}>
                        <Typography variant="h6" color="error">
                            Erros ao enviar mensagens:
                        </Typography>
                        <Box>
                            {errorDetails.map((erro, index) => (
                                <Typography key={index} variant="body2" color="error">
                                    {`${erro.nome} (${erro.numero}): ${erro.erro}`}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
