/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, styled, Collapse, Divider, } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Logo from "../../../image/logo.png";
import { iconMap } from "./iconMap";  

const drawerWidth = 200;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Sidebar = (props) => {
  const { open } = props;
  const [user, setUser] = React.useState({});
  const [isExpand, setIsExpand] = React.useState(999);
  const [menus, setMenus] = React.useState([]);

  React.useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);

      // Defina a ordem desejada
      const customOrder = [1, 2, 3, 4, 5, 8, 7, 6];

      // Ordene os menus com base na ordem especificada
      const orderedMenus = storedUser.menus.sort((a, b) => {
        return customOrder.indexOf(a.id) - customOrder.indexOf(b.id);
      });

      setMenus(orderedMenus);
    }
  }, []);

  const handleClickMenu = (index) => {
    setIsExpand((state) => (index === state ? 999 : index));
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#88be4d ",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <img
          src={Logo}
          alt="Logo"
          style={{ marginRight: "69px", height: "110px", marginTop: "8px" }}
        />
      </DrawerHeader>

      <Divider />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {menus && menus.map((menu, index) => {
            const IconComponent = iconMap[menu.icon] || null;

            return (
              <ListItem key={menu.name} disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={menu.route}
                  onClick={() => handleClickMenu(index)}
                >
                  {IconComponent && (
                    <ListItemIcon>
                      <IconComponent />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={menu.name} />
                  {menu.submenus.length > 0 ? (
                    isExpand === index ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
                {menu.submenus.length > 0 && (
                  <Collapse
                    in={isExpand === index}
                    timeout="auto"
                    unmountOnExit
                    key={menu.name}
                  >
                    <List component="div" disablePadding>
                      {menu.submenus.map((submenu) => (
                        <ListItemButton
                          key={submenu.id}
                          component={RouterLink}
                          to={submenu.route}
                        >
                          <ListItemText primary={submenu.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
