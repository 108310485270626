import { useDispatch } from "react-redux";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Select, TextField, Tooltip, Typography } from "@mui/material";
import { SelectAutoComplete } from "../../components";
import { useState } from "react";

import { changeloading } from "../../store/actions/loading.action";
import UsersService from "../../services/UsersService";
import { changeNotify } from "../../store/actions/notify.actions";
import { ControlPoint } from "@mui/icons-material";
import { modalStyleInput } from "../../themes/stylesGlobal";
import CustomButton from "../../components/Button/CustomButon";

const schema = yup.object({
    nome_colaborador: yup
        .object()
        .required('Nome do colaborador é obrigatório')
        .nullable(),
    certificado_treinamento_id: yup
        .object()
        .required('Nome do treinamento é obrigatório')
        .nullable(),

});



export default function CadastrarCertificado() {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(schema) });
    const [empresa, setEmpresa] = useState('');
    const [treinameto, setTreinamento] = useState('');
    const [selectKey, setSelectKey] = useState(0);
    const [openModalButton, setOpenModalButton] = useState(false);

    const handleOpenModalButton = () => { setOpenModalButton(true); };
    const handleCloseModalButton = () => { setOpenModalButton(false); };

    const options = ['Online', 'Presencial'];


    const onSubmitForm = async (data) => { 
    //    dispatch(changeloading({ open: true, msg: 'Salvando certificado...' }));
        let newdata = {
            ...data,
            funcionario_id: data.nome_colaborador.id,
            certificado_treinamento_id: data.certificado_treinamento_id.id
        };
        try {
            const res = await UsersService.create(newdata, 'add_certificado');
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            setOpenModalButton(false);
            setSelectKey(prevKey => prevKey + 1);
        } catch (error) {         
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.message }));
        }
    }

    const busca_empresa = (colaborador) => {
        setEmpresa(colaborador.nome_razao_social);
    };


    const handleSalvarTreinamento = () => {
        dispatch(changeloading({ open: true, msg: 'Salvando treinamento...' }));
        let objCertificado = {
            nome: treinameto
        };
        UsersService.create(objCertificado, 'certificado_treinamento').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
            setOpenModalButton(false);
            setSelectKey(prevKey => prevKey + 1);
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.message }));
        });
    }
    return (
        <Box component={Paper} padding={3} elevation={4}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={5} lg={3}>
                        <FormControl fullWidth>
                            <SelectAutoComplete
                                apiUrl="funcionario"
                                label="Nome Colaborador"
                                onChange={(newValue) => {
                                    setValue('nome_colaborador', newValue);
                                    busca_empresa(newValue); // Chama a função busca_empresa
                                }} />
                            <Typography variant='subtitle2' color="error">{errors?.nome_colaborador?.message}</Typography>
                            {empresa && (
                                <Box marginTop={1} marginLeft={1}>
                                    <Typography variant='subtitle1' color='#1d1c1c'>
                                        {`Esse funcionário pertence à empresa: ${empresa}`}
                                    </Typography>
                                </Box>
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={2}>
                        <Box display='flex'>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    key={selectKey}
                                    apiUrl="certificado_treinamento"
                                    label="Treinamentos"
                                    onChange={(newValue) => setValue('certificado_treinamento_id', newValue)}
                                />
                                <Typography variant='subtitle2' color="error">{errors?.certificado_treinamento_id?.message}</Typography>
                            </FormControl>
                            <Tooltip title="Adicionar treinamentos">
                                <IconButton onClick={handleOpenModalButton}>
                                    <ControlPoint color="success" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel>Selecione o modo do treinamento</InputLabel>
                            <Select
                                {...register('modo')}
                                size='small'
                                ful
                            >
                                {options.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={2}>
                        <TextField
                            type="date"
                            label="Data de Emissão"
                            fullWidth
                            size='small'
                            {...register("data_emissao")}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={2}>
                        <TextField
                            type="date"
                            label="Data de Validade"
                            fullWidth
                            size='small'
                            {...register("data_validade")}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <CustomButton type='submit' variant='contained'>Salvar</CustomButton>
                    </Grid>
                </Grid>
            </form>


            <Modal
                open={openModalButton}
                onClose={handleCloseModalButton}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyleInput}>
                    <Box>
                        <TextField
                            label='Treinamentos'
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={treinameto}
                            onChange={(e) => setTreinamento(e.target.value)}
                        />
                        <Box marginTop={2}>
                            <Button onClick={handleSalvarTreinamento}>Salvar</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}