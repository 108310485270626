import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, Button, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import { modalStyle } from "../../themes/stylesGlobal";
import { SelectAutoComplete } from '../../components'; // Importa o componente de seleção
import UsersService from '../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeNotify } from '../../store/actions/notify.actions';
import { changeloading } from '../../store/actions/loading.action';

function EditarCertificado({ open, onClose, dadosIntegracao, onUpdate }) {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, reset } = useForm();

    const [selectedFuncionario, setSelectedFuncionario] = useState(null);
    const [selectedTreinamento, setSelectedTreinamento] = useState(null);

    useEffect(() => {
        if (dadosIntegracao) {          
            setSelectedFuncionario({
                id: dadosIntegracao.funcionario_id,
                nome: dadosIntegracao.nome_funcionario,
            });

            setSelectedTreinamento({
                id: dadosIntegracao.certificado_treinamento_id,
                nome: dadosIntegracao.certificado_treinamento_nome,
            });

            setValue('modo', dadosIntegracao.modo);
            setValue('data_emissao', dadosIntegracao.data_emissao); 
            setValue('data_validade', dadosIntegracao.data_validade);
        } else {
            reset(); 
        }
    }, [dadosIntegracao, setValue, reset]);

    const onSubmit = async (data) => {
        dispatch(changeloading({ open: true, msg: 'Salvando...' }));
     
        try {
            const payload = {
                funcionario_id: selectedFuncionario?.id,
                certificado_treinamento_id: selectedTreinamento?.id,
                modo: data.modo,
                data_emissao: data.data_emissao,
                data_validade: data.data_validade,
            };

            const res = await UsersService.patch(dadosIntegracao.numero_certificado, payload, 'certificado');
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: 'Certificado atualizado com sucesso!' }));
            console.log(res)
            if (onUpdate) {
                onUpdate(res.certificado); // Passa a linha atualizada para a tabela
            }
            

            onClose();
        } catch (error) {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({
                open: true,
                class: 'error',
                msg: error.response?.data?.message || 'Erro ao atualizar o certificado.',
            }));
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-editar-certificado" aria-describedby="modal-editar-certificado-descricao">
            <Box sx={modalStyle}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {/* Campo para selecionar o funcionário */}
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    apiUrl="funcionario"
                                    label="Funcionário"
                                    defaultValue={selectedFuncionario}
                                    onChange={(newValue) => setSelectedFuncionario(newValue)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Campo para selecionar o certificado de treinamento */}
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <FormControl fullWidth>
                                <SelectAutoComplete
                                    apiUrl="certificado_treinamento"
                                    label="Certificado de Treinamento"
                                    defaultValue={selectedTreinamento}
                                    onChange={(newValue) => setSelectedTreinamento(newValue)}
                                />
                            </FormControl>
                        </Grid>

                        {/* Campo para selecionar o modo */}
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel>Modo</InputLabel>
                                <Select
                                    variant="outlined"
                                    size="small"
                                    defaultValue={dadosIntegracao?.modo || ''}
                                    {...register('modo')}
                                >
                                    <MenuItem value="online">Online</MenuItem>
                                    <MenuItem value="presencial">Presencial</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Campo para data de emissão */}
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <TextField
                                type="date"
                                label="Data de Emissão"
                                fullWidth
                                size="small"
                                {...register('data_emissao')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        {/* Campo para data de validade */}
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <TextField
                                type="date"
                                label="Data de Validade"
                                fullWidth
                                size="small"
                                {...register('data_validade')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        {/* Botão de salvar */}
                        <Grid item xs={3}>
                            <Button size="small" type="submit" variant="contained" color="primary" fullWidth>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
}

export default EditarCertificado;
