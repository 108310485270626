import { Box, Paper, Tab, Tabs, Typography } from '@mui/material'
import TabelaCertificados from './tabelaCertificado' 

import React, { useState } from 'react'
import { CustomTabPanel } from '../../components';
import CadastrarCertificado from './cadastrarCertificado';

export default function Cetificado() {

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box component={Paper} padding={1}>
        <Typography variant='h3'>Certificados</Typography>
      </Box>
      <Box marginTop={2} sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            orientation={window.innerWidth < 600 ? 'vertical' : 'horizontal'}
            sx={{
              '& .MuiTab-root': {
                marginRight: 2,
                '@media (max-width: 600px)': {
                  marginRight: 0,
                  marginBottom: 1, // Espaço entre as abas empilhadas
                },
              },
            }}
          >
            <Tab label="Cadastrar novo Certificado" />
            <Tab label="Tabela de Certificados" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CadastrarCertificado />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
           <TabelaCertificados />
        </CustomTabPanel>
      </Box>
    </Box>
  )
}
