import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, TextField } from "@mui/material";
import { TableComponet, Confirm } from "../../components";
import { useDebounce } from "../../hooks/UseDebounce";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import EditarCertificado from './EditarCertificado';
import UsersService from "../../services/UsersService";

const headers = [
    { id: "numero_certificado", label: "Numero do certificado", props: { align: 'center' } },
    { id: "nome_funcionario", label: "Funcionário", props: { align: 'left' } },
    { id: "nome_razao_social", label: "Empresa", props: { align: 'left' } },
    { id: "certificado_treinamento_nome", label: "Treinamento", props: { align: 'left' } },
    { id: "modo", label: "Modo de Treinamento", props: { align: 'left' } },
    { id: "data_emissao", label: "Data de Emissão", props: { align: 'center' } },
    { id: "data_validade", label: "Data de Validade", props: { align: 'center' } },
    { id: 'actionRows', label: 'Ações', props: { align: 'right' } },
];

function TabelaCertificado() {
    const dispatch = useDispatch();
    const [integracoes, setIntegracoes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
    const [totalPage, setTotalPages] = useState(0);
    const { debounce } = useDebounce(1000); // Atraso de 1 segundo
    const [openModalIntegracao, setOpenModalIntegracao] = useState(false);
    const [dadosIntegracao, setDadosIntegracao] = useState(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const res = await UsersService.getPagination({ search: searchTerm }, 'get_certificado');
            setIntegracoes(res.data || []);
            setTotalPages(res.totalPage || 0);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        } finally {
            setLoading(false);
        }
    }, [searchTerm]);

    useEffect(() => {
        debounce(fetchData);
    }, [searchTerm, tableUpdateTrigger, fetchData]);

    const handleEdit = (id) => {
        setDadosIntegracao(id);
        setOpenModalIntegracao(true);
    };

    const handleUpdateIntegracao = () => {
        setTableUpdateTrigger((prev) => prev + 1);
        setOpenModalIntegracao(false);
    };

    return (
        <Box>
            {/* Campo de Pesquisa */}
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="Pesquisar"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid>
            </Grid>

            {/* Tabela */}
            <Box marginTop={3}>
                <TableComponet
                    headers={headers}
                    data={integracoes}
                    labelCaption={'Nenhuma integração encontrada!'}
                    labelTable={'Integrações'}
                    handlerEditarAction={(id) => handleEdit(id)}
                    loading={loading}
                    setData={setIntegracoes}
                    tableUpdateTrigger={tableUpdateTrigger}
                    handlerRequest={async (page, size) => {
                        setLoading(true);
                        UsersService.getPagination({ search: searchTerm }, 'get_certificado', page, size).then((data) => {
                            setLoading(false);
                            setIntegracoes(data.data || []);
                            setTotalPages(data.totalPages || 0);
                            return data;
                        });
                        return [];
                    }}
                />
            </Box>
            {dadosIntegracao && (
                <EditarCertificado
                    open={openModalIntegracao}
                    onClose={() => setOpenModalIntegracao(false)}
                    dadosIntegracao={dadosIntegracao}
                    onUpdate={handleUpdateIntegracao}
                />
            )}
        </Box>
    );
}

export default TabelaCertificado;
